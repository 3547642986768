import React from "react"

//material
import {
    Box, Button,
    Card,
    CardContent,
    Grid, InputAdornment, TextField
} from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { LoadingButton } from "@mui/lab"

//apollo
import { Mutation } from "@apollo/client/react/components"
import { PASSWORD_RESET } from "../../queries/auth/userQueries"

//formik
import { Formik } from "formik"
import * as Yup from "yup"

//router
import { useNavigate, useParams } from "react-router-dom"
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"

//notifications
import { ErrorNotification, SuccessNotification } from "../../components/Notifications/Notifications"


export default function ResetPasswordPage(props) {
    const navigate = useNavigate()
    const {token} = useParams()

    const notifyError = message => ErrorNotification(message)
    const notifySuccess = message => SuccessNotification(message)

    const [wasReset, setWasReset] = React.useState(null)
    const [message, setMessage] = React.useState("")

    const validationSchema = Yup.object({
        password1: Yup.string()
            .required('field required')
            .min(8, 'the password most contain 8 characters at least'),
        password2: Yup.string()
            .required('field required')
            .when("password1", {
                is: val => (!!(val && val.length > 0)),
                then: Yup.string().oneOf(
                    [Yup.ref("password1")],
                    "passwords does not match"
                )
            })
    })

    function getErrors(errors) {
        let errorsObject = {}
        Object.entries(errors).forEach(([field, fieldErrors]) => {
            if (field === "nonFieldErrors") {
                fieldErrors.forEach(error => notifyError(error.message))
            } else {
                let errorMessages = []
                fieldErrors.forEach(error => errorMessages.push(error.message))
                errorsObject[field] = errorMessages
            }
        })
        return errorsObject
    }

    const [showPassword1, setShowPassword1] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const toggleVisibilityPassword1 = () => {
        setShowPassword1(!showPassword1)
    }
    const toggleVisibilityPassword2 = () => {
        setShowPassword2(!showPassword2)
    }

    const TableHeaderBar = () => {
        return (
            <AppBar position="static">
                <Toolbar>
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{
                            mr: 2,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Reset password
                    </Typography>
                </Toolbar>
            </AppBar>
        )
    }

    function handleClickAfterRequest(formik) {
        if (wasReset) {
            navigate("/")
        }
        if (wasReset === false) {
            setWasReset(null)
            setMessage("")
            formik.resetForm()
        }
    }

    return (
        <Box sx={{width: "100%", mt: 3, display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Card variant={"elevation"} sx={{p: 1, width: {xs: 1, sm: 1, md: 3/4, lg: 1/2, xl: 1/3}}}>
                <TableHeaderBar/>
                <CardContent>
                    <Grid container justifyContent={"center"}>
                        <Mutation mutation={PASSWORD_RESET}>
                            {
                                (register, { loading, error, data}) => (
                                    <Formik
                                        initialValues={
                                            {
                                                password1: '',
                                                password2: ''
                                            }
                                        }
                                        validationSchema={validationSchema}
                                        onSubmit={(data, { setErrors }) => {
                                            register({
                                                variables: {
                                                    token: token,
                                                    password1: data.password1,
                                                    password2: data.password2
                                                }
                                            }).then(
                                                (response) => {
                                                    let dataResponse = response.data.passwordReset

                                                    const errors = dataResponse.errors
                                                    if (errors) {
                                                        setErrors(getErrors(errors))
                                                    }

                                                    if (dataResponse.success) {
                                                        setWasReset(true)
                                                        setMessage("Done")
                                                        notifySuccess("Your new password has been established, now you can log in")
                                                        navigate("/")
                                                    } else {
                                                        setWasReset(false)
                                                        setMessage("Try again")
                                                        notifyError("Request password reset failed, try again")
                                                    }
                                                }
                                            ).catch(() => {
                                                setWasReset(false)
                                                setMessage("Reset password failed, try again")
                                            })
                                        }}
                                    >
                                        {
                                            formik => (
                                                <form onSubmit={formik.handleSubmit}>
                                                    <Grid container spacing={3}>
                                                        <Grid container item xs={12} spacing={2}>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    name="password1"
                                                                    label="New password"
                                                                    size={"small"}
                                                                    type={showPassword1 ? "text" : "password"}
                                                                    fullWidth
                                                                    variant={"filled"}
                                                                    {...formik.getFieldProps("password1")}
                                                                    error={formik.errors.password1 && formik.touched.password1}
                                                                    helperText={(formik.errors.password1 && formik.touched.password1) && formik.errors.password1}
                                                                    InputProps={{
                                                                        endAdornment:
                                                                            <InputAdornment
                                                                                position="end"
                                                                                onClick={toggleVisibilityPassword1}
                                                                            >
                                                                                {showPassword1 ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                                                            </InputAdornment>,
                                                                    }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    name="password2"
                                                                    label="Confirm Password"
                                                                    size={"small"}
                                                                    type={showPassword2 ? "text" : "password"}
                                                                    fullWidth
                                                                    variant={"filled"}
                                                                    {...formik.getFieldProps("password2")}
                                                                    error={formik.errors.password2 && formik.touched.password2}
                                                                    helperText={(formik.errors.password2 && formik.touched.password2) && formik.errors.password2}
                                                                    InputProps={{
                                                                        endAdornment:
                                                                            <InputAdornment
                                                                                position="end"
                                                                                onClick={toggleVisibilityPassword2}
                                                                            >
                                                                                {showPassword2 ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                                                            </InputAdornment>,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        {
                                                            wasReset === null
                                                            && <Grid container item xs={12} justifyContent={"flex-end"}>
                                                                <Button
                                                                    color={"secondary"}
                                                                    variant={"contained"}
                                                                    size={"small"}
                                                                    sx={{m: 1}}
                                                                    onClick={() => navigate("/")}
                                                                >
                                                                    Cancel
                                                                </Button>

                                                                <LoadingButton
                                                                    type={"submit"}
                                                                    color={"primary"}
                                                                    variant={"contained"}
                                                                    size={"small"}
                                                                    sx={{m: 1}}
                                                                    disabled={!formik.isValid}
                                                                    loading={loading}
                                                                >
                                                                    Reset
                                                                </LoadingButton>
                                                            </Grid>
                                                        }

                                                        {
                                                            wasReset !== null
                                                            && <Grid container item xs={12} justifyContent={"center"}>
                                                                <Button
                                                                    color={wasReset ? "primary" : "error"}
                                                                    variant={"outlined"}
                                                                    size={"small"}
                                                                    onClick={() => handleClickAfterRequest(formik)}
                                                                >
                                                                    {message}
                                                                </Button>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </form>
                                            )
                                        }
                                    </Formik>
                                )
                            }
                        </Mutation>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}