import * as React from "react"

//material
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import {
    Card,
    CardContent,
    Collapse,
    TextField,
    TableSortLabel,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    MenuItem,
} from "@mui/material"
import {
    CancelOutlined,
    ExpandLessOutlined,
    ExpandMoreOutlined,
    SearchOffOutlined,
    SearchOutlined,
    PersonOutline,
    CreditCardOutlined,
    HistoryOutlined,
    DeleteOutlined,
} from "@mui/icons-material"

//apollo
import { useLazyQuery, useQuery, useMutation } from "@apollo/client"
import { LOAD_ALL_USERS, CREATE_PAYMENT, DELETE_USER } from "../../../queries/admin/adminUsersQueries"

//styles
import tableStyles from "../../../assets/styles/tableStyles"

//components
import { ErrorNotification, SuccessNotification } from "../../../components/Notifications/Notifications"

//utils
import { ConfirmTransition, LoadingSkeleton, TableNoData } from "../../../utils/tableUtils"

//styles
import quizLayoutStyles from "../../../assets/styles/quizLayoutStyles"
import { visuallyHidden } from "@mui/utils"
import TablePagination from "@mui/material/TablePagination"
import { format } from "date-fns"
import { whiteColor } from "../../../assets/styles/utils"
import { LoadingButton } from "@mui/lab"
import { Mutation } from "@apollo/client/react/components"
import { LOAD_ALL_PAYMENTS } from "../../../queries/auth/userSubscriptionsQueries"

export default function AdminUsers() {
    const notifySuccess = (message) => SuccessNotification(message)
    const notifyError = (message) => ErrorNotification(message)

    //pagination
    const [order, setOrder] = React.useState("desc")
    const [orderBy, setOrderBy] = React.useState("date_joined")
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    //save server response data (page info)
    const [totalItemsPagination, setTotalItemsPagination] = React.useState(-1)
    const [startCursorPagination, setStartCursorPagination] = React.useState(null)
    const [endCursorPagination, setEndCursorPagination] = React.useState(null)

    const [queryVariables, setQueryVariables] = React.useState({
        orderBy: order === "asc" ? [orderBy] : [`-${orderBy}`],
        wordFilter: null,
        first: rowsPerPage,
        last: null,
        beforeCursor: null,
        afterCursor: null,
    })

    const setPaginationResponse = (data) => [
        setTotalItemsPagination(data.allUsers.totalCount),
        setStartCursorPagination(data.allUsers.pageInfo.startCursor),
        setEndCursorPagination(data.allUsers.pageInfo.endCursor),
    ]

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setQueryVariables({
                ...queryVariables,

                first: rowsPerPage,
                last: null,
                beforeCursor: null,
                afterCursor: endCursorPagination,
            })
        } else {
            setQueryVariables({
                ...queryVariables,

                first: null,
                last: rowsPerPage,
                beforeCursor: startCursorPagination,
                afterCursor: null,
            })
        }
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            first: parseInt(event.target.value, 10),
            last: null,
            beforeCursor: null,
            afterCursor: null,
        })
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            orderBy: order === "asc" ? [property] : [`-${property}`],
            first: rowsPerPage,
            last: null,
            beforeCursor: null,
            afterCursor: null,
        })
    }

    const handleSearchInputChange = (descriptionValue) => {
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            userFilter: descriptionValue,

            first: rowsPerPage,
            last: null,
            beforeCursor: null,
            afterCursor: null,
        })
    }

    //display search field
    const [showSearchInput, setShowSearchInput] = React.useState(false)
    const toggleSearchInput = () => {
        setShowSearchInput(!showSearchInput)
    }

    //search field
    const [searchValue, setSearchValue] = React.useState("")

    const handleSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const { data, loading } = useQuery(LOAD_ALL_USERS, {
        variables: queryVariables,
        onCompleted: (data1) => {
            setPaginationResponse(data1)
        },
        fetchPolicy: "network-only",
    })

    const [userPayments, setUserPayments] = React.useState(null)
    const [loadUserPayments, { loading: loadingUserPayments }] = useLazyQuery(LOAD_ALL_PAYMENTS, {
        onCompleted: (data) => {
            if (!!data.allPayments?.edges) {
                setUserPayments(data.allPayments.edges)
            }
        },
    })

    const TableHeaderBar = (props) => {
        const { handleSearchInputChange, searchValue, handleSearchValueChange, showSearchInput, toggleSearchInput } =
            props

        const handlePressEnter = (e) => {
            if (e.keyCode === 13) {
                applySearch()
            }
        }

        const applySearch = () => {
            handleSearchInputChange(searchValue)
            toggleSearchInput()
        }

        const cancelFilters = () => {
            setSearchValue("")
            handleSearchInputChange("")
            toggleSearchInput()
        }

        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                    {showSearchInput ? (
                        <TextField
                            placeholder={"Search by username..."}
                            autoFocus
                            size={"small"}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <Tooltip title={"Cancel search"}>
                                        <IconButton size={"small"} onClick={cancelFilters}>
                                            <CancelOutlined />
                                        </IconButton>
                                    </Tooltip>
                                ),
                                startAdornment: (
                                    <Tooltip title={"Search by username"}>
                                        <IconButton size={"small"} onClick={applySearch}>
                                            <SearchOutlined />
                                        </IconButton>
                                    </Tooltip>
                                ),
                            }}
                            value={searchValue}
                            onChange={handleSearchValueChange}
                            onKeyDown={handlePressEnter}
                        />
                    ) : (
                        <>
                            <Box>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    sx={{
                                        mr: 2,
                                        fontFamily: "monospace",
                                        fontWeight: 700,
                                        letterSpacing: ".3rem",
                                        color: "inherit",
                                        textDecoration: "none",
                                    }}
                                >
                                    Users
                                </Typography>
                            </Box>

                            <Box sx={{ display: "flex" }}>
                                <Tooltip title="Filter data">
                                    <IconButton
                                        aria-label="filter data"
                                        color={searchValue ? "secondary" : "default"}
                                        onClick={toggleSearchInput}
                                    >
                                        {searchValue ? <SearchOutlined /> : <SearchOffOutlined />}
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        )
    }

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } = props

        const headCells = [
            {
                id: "username",
                label: "User",
                sort: true,
                padding: "none",
            },
            {
                id: "name",
                label: "Name",
                sort: false,
                padding: "normal",
            },
            {
                id: "subscrption",
                label: "Subscrption",
                sort: false,
                padding: "none",
            },
            {
                id: "date_joined",
                label: "Joined",
                sort: true,
                padding: "normal",
            },
        ]

        const createSortHandler = (property) => () => {
            onRequestSort(property)
        }

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={"center"}
                            padding={headCell.padding}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell.sort ? (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : "asc"}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            ) : (
                                headCell.label
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    //expand description
    const [expandIndex, setExpandIndex] = React.useState(null)
    function handleExpand(rowIndex) {
        if (rowIndex === expandIndex) return setExpandIndex(null)

        return setExpandIndex(rowIndex)
    }

    function getPaymentType(paymentType) {
        switch (paymentType) {
            case "m":
                return "Monthly"
            case "s":
                return "Semester"
            case "y":
                return "Yearly"
            default:
                return "Unlimited"
        }
    }

    const [selectedUser, setSelectedUser] = React.useState(null)

    const [openPaymentForm, setOpenPaymentForm] = React.useState(false)

    const handleOpenPaymentForm = (e, user) => {
        e.stopPropagation()

        setSelectedUser(user)
        setOpenPaymentForm(true)
    }

    const handleClosePaymentForm = () => {
        setSelectedUser(null)
        setOpenPaymentForm(false)
    }

    function NewPaymentDialog(props) {
        const { open, handleClose, selectedUser, loading, createPayment } = props

        const [paymentType, setPaymentType] = React.useState(5)

        const createPaymentHandler = () =>
            createPayment({
                variables: {
                    client: selectedUser.client?.id,
                    value: paymentType,
                },
            }).then(
                (response) => {
                    if (response.data.createPayment.payment.id) {
                        notifySuccess("New payment registered")
                    }
                    handleClose()
                },
                () => {
                    notifyError("Payment registration failed, try again")
                    handleClose()
                }
            )

        return (
            <Dialog open={open} TransitionComponent={ConfirmTransition} keepMounted onClose={handleClose} fullWidth>
                <DialogTitle>Register payment</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        name={"paymentType"}
                        label="Type"
                        variant={"filled"}
                        size={"small"}
                        fullWidth
                        value={paymentType}
                        onChange={(e) => setPaymentType(e.target.value)}
                        select
                    >
                        <MenuItem value={5}>Montly (5 USD)</MenuItem>
                        <MenuItem value={15}>Semester (15 USD)</MenuItem>
                        <MenuItem value={25}>1 year (25 USD)</MenuItem>
                        <MenuItem value={50}>Forever (50 USD)</MenuItem>
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" disabled={loading}>
                        Cancel
                    </Button>
                    <LoadingButton
                        color={"primary"}
                        variant={"contained"}
                        loading={loading}
                        onClick={createPaymentHandler}
                    >
                        Create
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        )
    }

    const [openUserPaymentsForm, setOpenUserPaymentsForm] = React.useState(false)

    const handleOpenUserPaymentsForm = (e, user) => {
        e.stopPropagation()

        loadUserPayments({
            variables: {
                clientId: user.client.id,
            },
        })

        setOpenUserPaymentsForm(true)
    }

    const handleCloseUserPaymentsForm = () => {
        setOpenUserPaymentsForm(false)
        setUserPayments(null)
    }

    function UserPaymentsDialog(props) {
        const { open, handleClose, userPayments, loading } = props

        return (
            <Dialog open={open} TransitionComponent={ConfirmTransition} keepMounted onClose={handleClose} fullWidth>
                <DialogTitle>User payments</DialogTitle>
                <DialogContent dividers>
                    {userPayments.length > 0 ? (
                        <TableContainer sx={{ maxHeight: "calc(70vh)" }}>
                            <Table aria-labelledby="tableTitle" size={"small"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userPayments.map((row) => (
                                        <TableRow key={row.node.id}>
                                            <TableCell>
                                                {format(new Date(row.node.transactionDate), "yyyy/MM/dd hh:mm")}
                                            </TableCell>
                                            <TableCell>
                                                {row.node.value} {row.node.currencyCode}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Typography variant="h6" align="center">
                            No payments
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" disabled={loading}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const [displayDeleteClient, setDisplayDeleteClient] = React.useState(false)
    const handleCloseDeleteClient = () => {
        setSelectedUser(null)
        setDisplayDeleteClient(false)
    }
    const handleOpenDeleteClient = (user) => {
        setSelectedUser(user)
        setDisplayDeleteClient(true)
    }

    function ConfirmDeleteClientDialog(props) {
        const { open, handleClose } = props

        const [deleteAccount, { loading: loadingDeleteClient }] = useMutation(DELETE_USER, {
            refetchQueries: [LOAD_ALL_USERS],
        })

        const deleteItem = () =>
            deleteAccount({
                variables: {
                    id: selectedUser.id,
                },
            }).then(
                (response) => {
                    if (response.data.deleteAccountByAdmin.found) {
                        notifySuccess("Account deleted")
                    }
                    handleClose()
                },
                () => {
                    notifyError("Delete account failed, try again")
                    handleClose()
                }
            )

        return (
            <div>
                <Dialog open={open} TransitionComponent={ConfirmTransition} keepMounted onClose={handleClose}>
                    <DialogTitle>Delete client</DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>Do you confirm to delete the client?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary" disabled={loadingDeleteClient}>
                            Cancel
                        </Button>
                        <LoadingButton
                            color={"primary"}
                            variant={"contained"}
                            loading={loadingDeleteClient}
                            onClick={deleteItem}
                        >
                            Confirm
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

    return (
        <Mutation mutation={CREATE_PAYMENT} refetchQueries={[LOAD_ALL_USERS, LOAD_ALL_PAYMENTS]}>
            {(createPayment, { loading: loadingCreate }) => (
                <Box sx={{ width: "100%" }}>
                    {openPaymentForm && !!selectedUser && (
                        <NewPaymentDialog
                            open={openPaymentForm}
                            handleClose={handleClosePaymentForm}
                            selectedUser={selectedUser}
                            loading={loadingCreate}
                            createPayment={createPayment}
                        />
                    )}

                    {openUserPaymentsForm && userPayments && (
                        <UserPaymentsDialog
                            open={openUserPaymentsForm}
                            handleClose={handleCloseUserPaymentsForm}
                            userPayments={userPayments}
                            loading={loadingUserPayments}
                        />
                    )}

                    {displayDeleteClient && !!selectedUser && (
                        <ConfirmDeleteClientDialog open={displayDeleteClient} handleClose={handleCloseDeleteClient} />
                    )}

                    <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                        <TableHeaderBar
                            handleSearchInputChange={handleSearchInputChange}
                            searchValue={searchValue}
                            handleSearchValueChange={handleSearchValueChange}
                            showSearchInput={showSearchInput}
                            toggleSearchInput={toggleSearchInput}
                        />
                        <CardContent>
                            {loading ? (
                                <LoadingSkeleton />
                            ) : !data?.allUsers?.edges?.length ? (
                                <TableNoData />
                            ) : (
                                <>
                                    <TableContainer sx={{ maxHeight: "calc(70vh)" }}>
                                        <Table aria-labelledby="tableTitle" size={"small"}>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={handleRequestSort}
                                            />
                                            <TableBody>
                                                {data?.allUsers?.edges?.map((row) => {
                                                    return (
                                                        <React.Fragment key={row.node.id}>
                                                            <TableRow hover tabIndex={-1} sx={tableStyles.row}>
                                                                <TableCell padding="none" align="center">
                                                                    <Box
                                                                        sx={{
                                                                            display: "inline-flex",
                                                                            alignItems: "center",
                                                                            p: "2px 4px",
                                                                            borderRadius: "3px",
                                                                            background: !row.node.isVerified
                                                                                ? "orange"
                                                                                : !row.node.isActive
                                                                                ? "red"
                                                                                : "transparent",
                                                                            color:
                                                                                !row.node.isVerified ||
                                                                                !row.node.isActive
                                                                                    ? "white"
                                                                                    : "black",
                                                                        }}
                                                                    >
                                                                        {(row.node.isSuperuser || row.node.isStaff) && (
                                                                            <PersonOutline fontSize="small" />
                                                                        )}

                                                                        {row.node.username}
                                                                    </Box>
                                                                </TableCell>

                                                                <TableCell padding="none">
                                                                    <Grid container>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            container
                                                                            justifyContent={"center"}
                                                                        >
                                                                            {row.node.name}
                                                                        </Grid>

                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            container
                                                                            justifyContent={"center"}
                                                                        >
                                                                            {row.node.lastName}
                                                                        </Grid>
                                                                    </Grid>
                                                                </TableCell>

                                                                <TableCell padding="none" align="center">
                                                                    {!!row.node.client?.paymentType &&
                                                                        !!row.node.client?.nextPayment && (
                                                                            <Box
                                                                                sx={{
                                                                                    p: "2px 0",
                                                                                    borderRadius: "3px",
                                                                                    color: whiteColor,
                                                                                    backgroundColor: row.node
                                                                                        .isActivePayment
                                                                                        ? "success.main"
                                                                                        : "error.main",
                                                                                }}
                                                                            >
                                                                                {getPaymentType(
                                                                                    row.node.client.paymentType
                                                                                )}
                                                                            </Box>
                                                                        )}
                                                                </TableCell>

                                                                <TableCell align="center">
                                                                    {new Date(row.node.dateJoined).toDateString()}
                                                                    <Box sx={tableStyles.actionColumn}>
                                                                        <Tooltip title={"Details"}>
                                                                            <IconButton
                                                                                size={"small"}
                                                                                sx={{ m: "3px" }}
                                                                                onClick={() =>
                                                                                    handleExpand(row.node.id)
                                                                                }
                                                                            >
                                                                                {expandIndex === row.node.id ? (
                                                                                    <ExpandLessOutlined />
                                                                                ) : (
                                                                                    <ExpandMoreOutlined />
                                                                                )}
                                                                            </IconButton>
                                                                        </Tooltip>

                                                                        {row.node.client && (
                                                                            <Tooltip title={"New Payment"}>
                                                                                <IconButton
                                                                                    color={"warning"}
                                                                                    size={"small"}
                                                                                    sx={{ m: "3px" }}
                                                                                    onClick={(e) =>
                                                                                        handleOpenPaymentForm(
                                                                                            e,
                                                                                            row.node
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <CreditCardOutlined
                                                                                        fontSize={"small"}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}

                                                                        {row.node.client && (
                                                                            <Tooltip title={"Payments record"}>
                                                                                <IconButton
                                                                                    color={"default"}
                                                                                    size={"small"}
                                                                                    sx={{ m: "3px" }}
                                                                                    onClick={(e) =>
                                                                                        handleOpenUserPaymentsForm(
                                                                                            e,
                                                                                            row.node
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <HistoryOutlined
                                                                                        fontSize={"small"}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}

                                                                        {row.node.client && (
                                                                            <Tooltip title={"Remove client"}>
                                                                                <IconButton
                                                                                    color={"error"}
                                                                                    size={"small"}
                                                                                    sx={{ m: "3px" }}
                                                                                    onClick={() =>
                                                                                        handleOpenDeleteClient(row.node)
                                                                                    }
                                                                                >
                                                                                    <DeleteOutlined
                                                                                        fontSize={"small"}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell style={{ padding: 0 }} colSpan={4}>
                                                                    <Collapse
                                                                        in={expandIndex === row.node.id}
                                                                        timeout="auto"
                                                                        unmountOnExit
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            spacing={1}
                                                                            sx={{ padding: "8px" }}
                                                                        >
                                                                            <Grid item xs={12}>
                                                                                <Typography
                                                                                    variant="subtitle1"
                                                                                    gutterBottom
                                                                                    component="div"
                                                                                >
                                                                                    Details
                                                                                </Typography>
                                                                            </Grid>

                                                                            {!row.node.isVerified && (
                                                                                <Grid item xs={12}>
                                                                                    <Typography
                                                                                        component="span"
                                                                                        variant="subtitle2"
                                                                                        gutterBottom
                                                                                        backgroundColor="orange"
                                                                                        color="white"
                                                                                        p={"3px"}
                                                                                    >
                                                                                        Not verified
                                                                                    </Typography>
                                                                                </Grid>
                                                                            )}

                                                                            {!row.node.isActive && (
                                                                                <Grid item xs={12}>
                                                                                    <Typography
                                                                                        variant="subtitle2"
                                                                                        component="span"
                                                                                        backgroundColor="red"
                                                                                        color={"white"}
                                                                                        p={"3px"}
                                                                                    >
                                                                                        Inactive user
                                                                                    </Typography>
                                                                                </Grid>
                                                                            )}

                                                                            {(row.node.isSuperuser ||
                                                                                row.node.isStaff) && (
                                                                                <Grid item xs={12}>
                                                                                    <Typography
                                                                                        variant="subtitle2"
                                                                                        gutterBottom
                                                                                        component="div"
                                                                                    >
                                                                                        SUPERUSER
                                                                                    </Typography>
                                                                                </Grid>
                                                                            )}

                                                                            <Grid item xs={12}>
                                                                                {row.node.username}
                                                                            </Grid>

                                                                            <Grid item xs={12}>
                                                                                {row.node.name} {row.node.lastName}
                                                                            </Grid>

                                                                            {row.node.client?.paymentType && (
                                                                                <Grid item xs={12}>
                                                                                    Subscription:{" "}
                                                                                    {getPaymentType(
                                                                                        row.node.client.paymentType
                                                                                    )}
                                                                                </Grid>
                                                                            )}

                                                                            {row.node.client?.nextPayment && (
                                                                                <Grid item xs={12}>
                                                                                    Payment{" "}
                                                                                    {
                                                                                        <Box
                                                                                            sx={{
                                                                                                component: "span",
                                                                                                display: "inline-flex",
                                                                                                p: "1px 4px",
                                                                                                color: whiteColor,
                                                                                                backgroundColor: row
                                                                                                    .node
                                                                                                    .isActivePayment
                                                                                                    ? "success.main"
                                                                                                    : "error.main",
                                                                                            }}
                                                                                        >
                                                                                            {row.node.isActivePayment
                                                                                                ? "active"
                                                                                                : "inactive"}
                                                                                        </Box>
                                                                                    }{" "}
                                                                                    {row.node.isActivePayment
                                                                                        ? "until"
                                                                                        : "since"}{" "}
                                                                                    {format(
                                                                                        new Date(
                                                                                            row.node.client.nextPayment
                                                                                        ),
                                                                                        "yyyy/MM/dd hh:mm"
                                                                                    )}
                                                                                </Grid>
                                                                            )}

                                                                            <Grid item xs={12}>
                                                                                {row.node.email}
                                                                            </Grid>

                                                                            <Grid item xs={12}>
                                                                                Joined on{" "}
                                                                                {new Date(
                                                                                    row.node.dateJoined
                                                                                ).toDateString()}
                                                                            </Grid>

                                                                            {row.node.lastLogin && (
                                                                                <Grid item xs={12}>
                                                                                    Last login on{" "}
                                                                                    {new Date(
                                                                                        row.node.lastLogin
                                                                                    ).toDateString()}
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    </Collapse>
                                                                </TableCell>
                                                            </TableRow>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <TablePagination
                                        component={"div"}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={2}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "rows per page",
                                            },
                                        }}
                                        count={totalItemsPagination}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Box>
            )}
        </Mutation>
    )
}
