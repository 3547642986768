import React from "react"

//material ui
import { Box, Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography } from "@mui/material"
import { CreditCardOutlined, DoneOutline, KeyboardDoubleArrowRightOutlined, TimerOutlined } from "@mui/icons-material"
import { NavLink, useNavigate } from "react-router-dom"

//styles
import statsCardStyles from "../../assets/styles/statsCardStyles"

//apollo
import { useLazyQuery, useQuery } from "@apollo/client"
import { Query } from "@apollo/client/react/components"
import { CLIENT_EXAM_STATS, EXAM_QUESTIONNAIRE } from "../../queries/auth/userExamQueries"
import { USER_DETAILS } from "../../queries/auth/userQueries"
import { I_PAID } from "../../queries/auth/userSubscriptionsQueries"

//notification
import { ErrorNotification } from "../../components/Notifications/Notifications"

//dates
import { format } from "date-fns"

//components
import CreateClient from "../../components/CreateClient"

export default function Dashboard() {
    const navigate = useNavigate()

    const notifyError = (message) => ErrorNotification(message)

    const StatsComponent = ({clientId}) => {
        const [loadExamQuestionnaire, { loading: loadingExamQuestionnaire }] = useLazyQuery(EXAM_QUESTIONNAIRE)

        const [startedTrainingExams, setStartedTrainingExams] = React.useState(null)
        const [finishedTrainingExams, setFinishedTrainingExams] = React.useState(null)

        const [startedTrainingQuestionnaire, setStartedTrainingQuestionnaire] = React.useState(null)
        const [finishedTrainingQuestionnaire, setFinishedTrainingQuestionnaire] = React.useState(null)

        const [trainingExamInProgress, setTrainingExamInProgress] = React.useState(null)

        const [startedExams, setStartedExams] = React.useState(null)
        const [startedQuestionnaire, setStartedQuestionnaire] = React.useState(null)

        const { loading: loadingExamStats } = useQuery(CLIENT_EXAM_STATS, {
            variables: { id: clientId },
            onCompleted: (data) => {
                if (data?.client?.id) {
                    const response = data.client
                    if (response.currentExamId) {
                        setTrainingExamInProgress({
                            currentExamId: response.currentExamId,
                            currentExamIdCodified: response.currentExamIdCodified,
                            currentQuestionnaireStartTime: response.currentQuestionnaireStartTime,
                        })
                    }

                    setStartedTrainingExams(
                        response.clientExam?.edges.filter(
                            (item) => item.node.examPurpose.name.toUpperCase() === "TRAIN"
                        ).length
                    )
                    setFinishedTrainingExams(
                        response.clientExam?.edges?.filter(
                            (item) => !!item.node.finishTime && item.node.examPurpose.name.toUpperCase() === "TRAIN"
                        ).length
                    )

                    let differentTrainingQuestionnaires = []
                    response.clientExam?.edges
                        .filter((item) => item.node.examPurpose.name.toUpperCase() === "TRAIN")
                        .forEach((item) => {
                            const index = differentTrainingQuestionnaires.findIndex(
                                (differentItem) =>
                                    differentItem.questionnaire.questionnaire.id === item.node.questionnaire.id
                            )
                            if (index === -1) {
                                differentTrainingQuestionnaires.push({
                                    questionnaire: item.node,
                                    finished: !!item.node.finishTime,
                                })
                            } else {
                                if (!!item.node.finishTime) {
                                    differentTrainingQuestionnaires[index].finished = true
                                }
                            }
                        })

                    setStartedTrainingQuestionnaire(differentTrainingQuestionnaires.length)
                    setFinishedTrainingQuestionnaire(
                        differentTrainingQuestionnaires.filter((item) => item.finished).length
                    )

                    setStartedExams(
                        response.clientExam?.edges?.filter(
                            (item) => item.node.examPurpose.name.toUpperCase() === "TEST"
                        ).length
                    )

                    let differentQuestionnaires = []
                    response.clientExam?.edges
                        .filter((item) => item.node.examPurpose.name.toUpperCase() === "TEST")
                        .forEach((item) => {
                            const index = differentQuestionnaires.findIndex(
                                (differentItem) => differentItem.questionnaire.id === item.node.questionnaire.id
                            )
                            if (index === -1) {
                                differentQuestionnaires.push(item.node)
                            }
                        })

                    setStartedQuestionnaire(differentQuestionnaires.length)
                }
            },
            fetchPolicy: "network-only",
        })

        const [paySubscriptionRequired, setPaySubscriptionRequired] = React.useState(false)
        const { loading: loadingIPaid } = useQuery(I_PAID, {
            onCompleted: (data) => {
                setPaySubscriptionRequired(!data?.iPaid?.isActive)
            },
            fetchPolicy: "network-only",
        })

        const MakePaymentCard = () => (
            <Card sx={statsCardStyles.cardContainer}>
                <Box sx={statsCardStyles.cardHeader}>
                    <CreditCardOutlined fontSize={"large"} sx={statsCardStyles.cardHeaderIconWarning} />
                </Box>
                {loadingIPaid ? (
                    <Grid container justifyContent={"center"} alignItems={"center"} sx={{ height: "160px" }}>
                        <CircularProgress color={"primary"} size={24} />
                    </Grid>
                ) : (
                    <>
                        <CardContent sx={statsCardStyles.cardContent}>
                            <NavLink to={"/quiz/subscription/pay"}>
                                <Grid container sx={{ mt: 2 }}>
                                    <Grid container item xs={12} justifyContent={"right"}>
                                        <Typography variant={"h5"} sx={{ fontSize: "22px" }}>
                                            Subscription payment required
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </NavLink>
                        </CardContent>
                        <CardActions sx={statsCardStyles.cardActions}>
                            <NavLink to={"/quiz/subscription/pay"}>
                                <Button endIcon={<KeyboardDoubleArrowRightOutlined />}>Make payment</Button>
                            </NavLink>
                        </CardActions>
                    </>
                )}
            </Card>
        )

        const Stats1Card = () => (
            <Card sx={statsCardStyles.cardContainer}>
                <Box sx={statsCardStyles.cardHeader}>
                    <Grid container>
                        <Grid item xs={"auto"}>
                            <DoneOutline fontSize={"large"} sx={statsCardStyles.cardHeaderIconTraining} />
                        </Grid>

                        <Grid container item xs={true} justifyContent={"center"} alignItems={"center"} sx={{ pt: 2 }}>
                            <Typography variant={"subtitle2"} sx={{ fontSize: 18, fontWeight: 600 }}>
                                Train
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                {loadingExamStats ? (
                    <Grid container justifyContent={"center"} alignItems={"center"} sx={{ height: "160px" }}>
                        <CircularProgress color={"primary"} size={24} />
                    </Grid>
                ) : (
                    <>
                        <CardContent sx={statsCardStyles.cardContent}>
                            <NavLink to={"/quiz/dashboard/training-exam-stats"}>
                                <Grid container>
                                    <Grid container item xs={12} justifyContent={"right"}>
                                        <Typography variant={"h5"}>
                                            {startedTrainingExams} <small>quiz started</small>
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} justifyContent={"right"}>
                                        <Typography variant={"h6"}>
                                            {finishedTrainingExams} <small>finished</small>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </NavLink>
                        </CardContent>
                        <CardActions sx={statsCardStyles.cardActions}>
                            <NavLink to={"/quiz/questionnaire/?purpose=train"}>
                                <Button endIcon={<KeyboardDoubleArrowRightOutlined />}>Start a new one</Button>
                            </NavLink>
                        </CardActions>
                    </>
                )}
            </Card>
        )

        const Stats2Card = () => (
            <Card sx={statsCardStyles.cardContainer}>
                <Box sx={statsCardStyles.cardHeader}>
                    <Grid container>
                        <Grid item xs={"auto"}>
                            <DoneOutline fontSize={"large"} sx={statsCardStyles.cardHeaderIconTraining} />
                        </Grid>

                        <Grid container item xs={true} justifyContent={"center"} alignItems={"center"} sx={{ pt: 2 }}>
                            <Typography variant={"subtitle2"} sx={{ fontSize: 18, fontWeight: 600 }}>
                                Train
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                {loadingExamStats ? (
                    <Grid container justifyContent={"center"} alignItems={"center"} sx={{ height: "160px" }}>
                        <CircularProgress color={"primary"} size={24} />
                    </Grid>
                ) : (
                    <>
                        <CardContent sx={statsCardStyles.cardContent}>
                            <NavLink to={"/quiz/dashboard/training-questionnaire-stats"}>
                                <Grid container>
                                    <Grid container item xs={12} justifyContent={"right"}>
                                        <Typography variant={"h5"}>
                                            {startedTrainingQuestionnaire} <small>questionnaires used</small>
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} justifyContent={"right"}>
                                        <Typography variant={"h6"}>
                                            {finishedTrainingQuestionnaire} <small>finished</small>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </NavLink>
                        </CardContent>
                        <CardActions sx={statsCardStyles.cardActions}>
                            <NavLink to={"/quiz/questionnaire/?purpose=train"}>
                                <Button endIcon={<KeyboardDoubleArrowRightOutlined />}>Start a new one</Button>
                            </NavLink>
                        </CardActions>
                    </>
                )}
            </Card>
        )

        function handleResumeExam(examId) {
            loadExamQuestionnaire({ variables: { id: trainingExamInProgress?.currentExamIdCodified } })
                .then((response) => {
                    if (response?.data?.exam?.questionnaire?.id) {
                        const questionnaireId = response.data.exam.questionnaire.id
                        navigate(`/quiz/questionnaire/${questionnaireId}/training/${examId}`)
                    }
                })
                .catch(() => {
                    notifyError("Load in progress exam details failed, try again")
                })
        }

        const Stats3Card = () => (
            <Card sx={statsCardStyles.cardContainer}>
                <Box sx={statsCardStyles.cardHeader}>
                    <Grid container>
                        <Grid item xs={"auto"}>
                            <TimerOutlined fontSize={"large"} sx={statsCardStyles.cardHeaderIconTraining} />
                        </Grid>

                        <Grid container item xs={true} justifyContent={"center"} alignItems={"center"} sx={{ pt: 2 }}>
                            <Typography variant={"subtitle2"} sx={{ fontSize: 18, fontWeight: 600 }}>
                                Train
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                {loadingExamQuestionnaire ? (
                    <Grid container justifyContent={"center"} alignItems={"center"} sx={{ height: "160px" }}>
                        <CircularProgress color={"primary"} size={24} />
                    </Grid>
                ) : (
                    <>
                        <CardContent sx={statsCardStyles.cardContent}>
                            <Grid container>
                                <Grid container item xs={12} justifyContent={"right"}>
                                    <Typography variant={"h5"}>
                                        {trainingExamInProgress && <small>Exam in progress</small>}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} justifyContent={"right"}>
                                    <Typography variant={"h5"}>
                                        {trainingExamInProgress && (
                                            <small>
                                                {" "}
                                                started at{" "}
                                                {format(
                                                    new Date(
                                                        trainingExamInProgress.currentQuestionnaireStartTime * 1000
                                                    ),
                                                    "yyyy/MM/dd hh:mm:ss"
                                                )}
                                            </small>
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={statsCardStyles.cardActions}>
                            <Button
                                endIcon={<KeyboardDoubleArrowRightOutlined />}
                                onClick={() => handleResumeExam(trainingExamInProgress.currentExamIdCodified)}
                            >
                                Resume train
                            </Button>
                        </CardActions>
                    </>
                )}
            </Card>
        )

        const Stats4Card = () => (
            <Card sx={statsCardStyles.cardContainer}>
                <Box sx={statsCardStyles.cardHeader}>
                    <Grid container>
                        <Grid item xs={"auto"}>
                            <DoneOutline fontSize={"large"} sx={statsCardStyles.cardHeaderIconExam} />
                        </Grid>

                        <Grid container item xs={true} justifyContent={"center"} alignItems={"center"} sx={{ pt: 2 }}>
                            <Typography variant={"subtitle2"} sx={{ fontSize: 18, fontWeight: 600 }}>
                                TEST
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                {loadingExamStats ? (
                    <Grid container justifyContent={"center"} alignItems={"center"} sx={{ height: "160px" }}>
                        <CircularProgress color={"primary"} size={24} />
                    </Grid>
                ) : (
                    <>
                        <CardContent sx={statsCardStyles.cardContent}>
                            <NavLink to={"/quiz/dashboard/exam-stats"}>
                                <Grid container>
                                    <Grid container item xs={12} justifyContent={"right"}>
                                        <Typography variant={"h5"}>
                                            {startedExams} <small>quiz started</small>
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} justifyContent={"right"}>
                                        <Typography variant={"h5"}>
                                            {startedQuestionnaire} <small>questionnaires used</small>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </NavLink>
                        </CardContent>
                        <CardActions sx={statsCardStyles.cardActions}>
                            <NavLink to={"/quiz/questionnaire/?purpose=test"}>
                                <Button endIcon={<KeyboardDoubleArrowRightOutlined />}>Start a new one</Button>
                            </NavLink>
                        </CardActions>
                    </>
                )}
            </Card>
        )

        return (
            <Box sx={{ width: 1 }}>
                <Grid container rowSpacing={6} columnSpacing={3} justifyContent={"center"}>
                    {paySubscriptionRequired && (
                        <Grid container item xs={12} sm={6} alignItems={"center"} justifyContent={"center"}>
                            <MakePaymentCard />
                        </Grid>
                    )}

                    <Grid container item xs={12} sm={6} alignItems={"center"} justifyContent={"center"}>
                        <Stats1Card />
                    </Grid>

                    <Grid container item xs={12} sm={6} alignItems={"center"} justifyContent={"center"}>
                        <Stats2Card />
                    </Grid>

                    {trainingExamInProgress && (
                        <Grid container item xs={12} sm={6} alignItems={"center"} justifyContent={"center"}>
                            <Stats3Card />
                        </Grid>
                    )}

                    <Grid container item xs={12} sm={6} alignItems={"center"} justifyContent={"center"}>
                        <Stats4Card />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Query query={USER_DETAILS}>
            {({ data }) => {
                if (!data) return <CircularProgress size={36} sx={{ color: "white" }} />

                if (data?.me2 && !data.me2.client) return <CreateClient user={data.me2} />

                return <StatsComponent clientId={data.me2.client.id} />
            }}
        </Query>
    )
}
